/* COURSES */

.courses_title {
  font-size: 96px;
  font-weight: 700;
}

.App {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  align-items: flex-start;
}

.filter-column {
  flex: 1;
  margin-right: 20px;
}

.courses-column {
  flex: 2;
  min-width: 300px;
}

.filter-column select,
.filter-column input {
  display: block;
  margin-bottom: 10px;
  background-color: #ECECEC;
  width: 300px;
  height: 40px;
  border-radius: 10px;
  caret-color: #000;
  color: black;
  font-weight: bold;
  padding-left: 10px;
}

.apply_button {
  background-color: var(--base-bg-color);
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  width: 200px;
  height: 40px;
  margin-top: 10px;
}

.course_pic {
  margin-left: -340px;
}

.course-item {
  margin-bottom: 20px;
}

.view-course-button {
  background-color: var(--base-bg-color);
  color: black;
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

@media (max-width: 768px) {

  .courses_title {
    font-size: 80px;
  }

  .App {
    flex-direction: column;
  }

  .apply_button {
    width: 100%;
    font-size: 10px;
  }

  .filter-column {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .filter-select {
    width: 300px;
    margin-bottom: 10px;
  }

  .filter-select select {
    width: 100%;
  }

  .apply_button {
    width: 100%;
    max-width: 100px;
  }

  .course-item {
    margin-bottom: 10px;
  }

  .view-course-button {
    width: 100%;
    margin-right: 20px;
  }
}

.course-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px 0;
}

/* COURSE DETAILS */
.course_hero {
  background-color: hsl(170, 75%, 41%);
  width: 100%; 
  height: auto; 
  padding: 20px; 
  box-sizing: border-box; 
}

.course_card {
  background-color: white !important;
  width: 100%; 
  max-width: 402px; 
  height: auto; 
  margin: 20px auto; 
  border-radius: 20px;
  padding: 20px; 
 
}

.course_card img {
  width: 100%; 
  border-radius: 20px;
}

.add_to_cart_button {
  background-color: hsla(351, 83%, 61%, 1);
  padding: 10px 100px; 
  border-radius: 5px;
  text-align: center; 
  display: inline-block; 
  margin-top: 20px; 
  font-weight: bold;
}

.course_detail_heading {
  font-size: 40px;
  font-weight: 700;
}

.include_text {
  font-size: 24px;
  font-weight: 400;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .course_hero {
    height: auto; 
    padding: 10px; 
  }

  .course_card {
    margin-left: 0; 
    padding: 10px; 
  }

  .course_detail_heading {
    font-size: 24px; 
  }

  .include_text {
    font-size: 18px; 
  }

  .add_to_cart_button {
    padding: 10px 15px; 
    font-size: 16px; 
  }
}

/* MODAL TO CHECK IF THE PERSON HAS LOGGED IN FOR ADD TO CART */
.add_to_cart_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_to_cart_modal_content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

/* COURSE VIDEO ACCORDIAN */
.accordion-container {
  width: 100%;
  max-width: 600px; 
  margin: 0 auto;
  border: 1px solid #ddd;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-header {
  background-color: #f0f0f0;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}

.accordion-body {
  padding: 10px;
  display: none;
}

.accordion-item.active .accordion-body {
  display: block;
}

/* CHECKOUT PAGE */
.small_title {
  font-size: 32px;
  font-weight: 700;
}

.total_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.total_section .total_price {
  display: flex;
  flex-direction: column;
}

.checkout_button {
  background-color: hsl(170, 75%, 41%);
  padding: 10px 20px;
  font-weight: 600;
  font-size: 32px;
  border-radius: 10px;
  margin-top: 10px;
}

.checkout_title {
  font-size: 96px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .checkout_title {
    font-size: 66px;
  }

  .checkout_button {
    padding: 10px 20px;
    font-size: 20px;
  }
}


/* completed modal */
.completion_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.completion_modal_content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* shadow for the modal */
}

.completion_modal h1 {
  font-size: 24px;
  margin-bottom: 16px;
}

.completion_modal button {
  margin-top: 16px;
  padding: 10px 20px;
  background-color: #4CAF50; /* green button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.completion_modal button:hover {
  background-color: #45a049; /* darker shade of green on hover */
}